<template>

  <div class="d-flex justify-content-center">

    <div class="test-parent-container">

      <div class="form-container invoices-list">


        <b-table  v-if="claims && claims.length" class="mb-2" id="invoice-table"
                 tdClass=""
                 bordered
                 sort-by="created_at"
                 :sort-desc="true"
                 :current-page="currentPage"
                 :per-page="perPage"
                 style="font-size: 12px"
                 selectable select-mode="single"
                 ref="invoiceTable"
                 @row-selected="onRowSelected"
                 :fields="table_fields"
                 :items="invoices">




          <template #cell(created_at)="data">

            <b>{{toDateStr(data.item.created_at)}}</b>

          </template>


          <template #cell(file)="data">

            {{ellipsis((data.item.orig_file || data.item.file),40)}}

          </template>

          <template #cell(status)="data">
            <b-badge class="full-width" variant="success" v-if="['COMPLETED', 'CONFIRMED'].includes(data.item.status)">completado</b-badge>
            <b-badge class="full-width" variant="warning" v-if="data.item.status === 'PENDING'">en cola</b-badge>
            <b-badge class="full-width" variant="info" v-if="data.item.status === 'PROCESSING'">procesando</b-badge>
            <b-badge class="full-width" variant="danger" v-if="data.item.status === 'ERROR'">error</b-badge>
          </template>


        </b-table>

        <Message v-else severity="info">No hay reclamaciones aún, crea una

          <router-link :to="'/'"> aquí </router-link>


        </Message>


      </div>



    </div>

  </div>






</template>

<script>



import {mapGetters} from "vuex";
import {RESET_CLAIM} from "@/store/actions.type";
import InvoiceList from '@/components/invoiceList';

export default {
  name: "test",
  components: {

  },

  data() {
    return {
      claims: [],
      currentPage: 1,
      perPage: 25,

      table_fields: [
        {
          key: 'created_at',
          label: 'Fecha',
          sortable: true
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true
        },
        {
          key: 'invoiceCount',
          label: 'Facturas',
          sortable: true
        },
        {
          key: 'total',
          label: 'Total Deuda',
          sortable: true
        },
      ],

    };
  },

  computed: {
    ...mapGetters([''])
  },
  methods: {

  },
  async mounted() {
  }



};
</script>

<style scoped>

.test-parent-container {
  margin-top: 50px;
  width: 550px;
}


</style>
